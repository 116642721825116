import { createPortal } from 'react-dom';

import $ from './modal.scss';

export function Modal({ children }: { children: React.ReactNode }) {
    return createPortal(
        <div className={$.modalBackground}>
            <div className={$.modal}>{children}</div>
        </div>,
        document.body,
    );
}
