import { useCallback, useLayoutEffect, useState } from 'react';

export function usePageDimensions(dependencies: any[] = []) {
    const [WH, setWH] = useState<[number, number]>([0, 0]);

    const updateWH = useCallback(() => {
        window.requestAnimationFrame(() => {
            setWH([window.outerWidth, window.outerHeight]);
        });
    }, []);

    useLayoutEffect(() => {
        updateWH();
        window.addEventListener('resize', updateWH);
        return () => {
            window.removeEventListener('resize', updateWH);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);

    return WH;
}
