import { Field, ErrorMessage, FieldAttributes, FormikState } from 'formik';
import cx from 'clsx';

import $ from './input.scss';

interface iProps {
    label: string | undefined;
}

function InputComponent({
    field,
    form: { touched, errors },
    label,
    ...props
}: iProps & {
    field: FieldAttributes<any>;
    form: FormikState<any>;
}): JSX.Element {
    const error = touched[field.name] && errors[field.name];

    return (
        <div className={$.wrapper}>
            {label && (
                <label className={$.label} htmlFor={field.name}>
                    {label}
                </label>
            )}
            <input className={cx($.root, error && $.error)} {...field} {...props} />
            <ErrorMessage name={field.name}>
                {(msg) => <div className={$.errorMessage}>{msg}</div>}
            </ErrorMessage>
        </div>
    );
}

export function Input(props: FieldAttributes<any> & iProps): JSX.Element {
    return <Field component={InputComponent} type='checkbox' {...props} />;
}
