import { iIconProps } from '../../icon';

export default function PodcastIcon({ size, ...props }: iIconProps): JSX.Element {
    return (
        <svg width={size} height={size} fill='none' viewBox='0 0 24 24' {...props}>
            <path
                fill='currentColor'
                fillRule='evenodd'
                d='M3 12a9 9 0 0 1 18 0 8.97 8.97 0 0 1-2.636 6.364 1 1 0 1 0 1.414 1.414A10.969 10.969 0 0 0 23 12c0-6.075-4.925-11-11-11S1 5.925 1 12c0 3.037 1.232 5.789 3.222 7.778a1 1 0 0 0 1.414-1.414A8.969 8.969 0 0 1 3 12Zm4 0a5 5 0 1 1 8.536 3.536 1 1 0 0 0 1.414 1.414 7 7 0 1 0-9.9 0 1 1 0 1 0 1.414-1.414A4.981 4.981 0 0 1 7 12Zm7.5 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm-4.827 5.494a2.332 2.332 0 1 1 4.654 0l-.322 4.636a2.01 2.01 0 0 1-4.01 0l-.322-4.636Z'
                clipRule='evenodd'
            />
        </svg>
    );
}
