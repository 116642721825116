/* eslint-disable jsx-a11y/label-has-associated-control */
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { Button, Icon, Input, Text } from '~components';
import { useNewsletter } from '~source/ui/hooks/use-newsletter';

import $ from './footer.scss';
import { Error } from '../../molecules';

const NewsletterSchema = Yup.object().shape({
    email: Yup.string().email('Ongeldig e-mailadres').required('Verplicht'),
});

interface iProps {
    pre: boolean;
}

export type FooterProps = iProps;

export function Footer({ pre }: iProps): JSX.Element {
    const { error, isSubscribed, subscribe } = useNewsletter();

    const handleSubscribe = useCallback(
        ({ email }: { email: string }) => {
            subscribe(email);
        },
        [subscribe],
    );

    return (
        <>
            {pre && (
                <div className={$.preFooter}>
                    <div>
                        <div>
                            <div>
                                <div className={$.preFooterHeading}>
                                    <Icon size={34} type='mail' />
                                    <p>Blijf op de hoogte</p>
                                </div>
                                <Text color='gray-light'>
                                    <label htmlFor='email'>
                                        Met onze nieuwsbrief blijf je onder andere op de hoogte van
                                        de laatste sportontwikkelingen.
                                    </label>
                                </Text>
                                <div className={$.preFooterButtons}>
                                    <Formik
                                        initialValues={{ email: '' }}
                                        validationSchema={NewsletterSchema}
                                        onSubmit={handleSubscribe}
                                    >
                                        <Form>
                                            <div>
                                                <Input
                                                    id='email'
                                                    name='email'
                                                    type='email'
                                                    placeholder='Vul je e-mailadres in'
                                                />
                                                {isSubscribed && (
                                                    <span>
                                                        Bedankt voor je aanmelding voor de Ziggo
                                                        Sport Totaal nieuwsbrief. Je ontvangt van
                                                        ons een e-mail waarin je je aanmelding kunt
                                                        bevestigen.
                                                    </span>
                                                )}
                                                {!isSubscribed && error && <Error error={error} />}
                                            </div>
                                            <Button
                                                label='Aanmelden'
                                                type='submit'
                                                disabled={isSubscribed}
                                            />
                                        </Form>
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <footer className={$.footer}>
                <div className={$.footerTop}>
                    <div>
                        <div>
                            <div>
                                <span>Meer Ziggo Sport?</span>
                                <div>
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href='https://www.facebook.com/ZiggoSport'
                                    >
                                        <Icon size={20} type='facebook' /> Facebook
                                    </a>
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href='https://www.instagram.com/ziggosport/'
                                    >
                                        <Icon size={20} type='instagram' /> Instagram
                                    </a>
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href='https://www.youtube.com/channel/UCueOZEvMffxjJyVO1fGiJNQ'
                                    >
                                        <Icon size={20} type='youtube' /> YouTube
                                    </a>
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href='https://x.com/ZiggoSport'
                                    >
                                        <Icon size={20} type='x' /> X
                                    </a>
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href='https://www.tiktok.com/@ziggosport'
                                    >
                                        <Icon size={20} type='tiktok' /> TikTok
                                    </a>
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href='https://www.ziggosport.nl/rondo2023'
                                    >
                                        <Icon size={20} type='podcast' /> Podcast
                                    </a>
                                </div>
                            </div>
                            <div>
                                <span>Meer Ziggo Voetbal?</span>
                                <div>
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href='https://www.facebook.com/ZiggoSportVoetbal/'
                                    >
                                        <Icon size={20} type='facebook' /> Facebook
                                    </a>
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href='https://www.instagram.com/ZiggoSportVoetbal/'
                                    >
                                        <Icon size={20} type='instagram' /> Instagram
                                    </a>
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href='https://www.youtube.com/channel/UCueOZEvMffxjJyVO1fGiJNQ'
                                    >
                                        <Icon size={20} type='youtube' /> YouTube
                                    </a>
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href='https://x.com/zs_voetbal'
                                    >
                                        <Icon size={20} type='x' /> X
                                    </a>
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href='https://www.tiktok.com/@ziggosport'
                                    >
                                        <Icon size={20} type='tiktok' /> TikTok
                                    </a>
                                </div>
                            </div>
                            <div>
                                <span>Meer Ziggo Racing?</span>
                                <div>
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href='https://www.facebook.com/ZiggoSportRacing/'
                                    >
                                        <Icon size={20} type='facebook' /> Facebook
                                    </a>
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href='https://www.instagram.com/ZiggoSportRacing/'
                                    >
                                        <Icon size={20} type='instagram' /> Instagram
                                    </a>
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href='https://www.youtube.com/channel/UCueOZEvMffxjJyVO1fGiJNQ'
                                    >
                                        <Icon size={20} type='youtube' /> YouTube
                                    </a>
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href='https://x.com/zs_racing'
                                    >
                                        <Icon size={20} type='x' /> X
                                    </a>
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href='https://www.tiktok.com/@ziggosport'
                                    >
                                        <Icon size={20} type='tiktok' /> TikTok
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={$.footerMain}>
                    <div>
                        <div>
                            <div className={$.footerMainMenu}>
                                <nav>
                                    <Text className={$.footerMainMenuTitle}>Ondersteuning</Text>
                                    <ul>
                                        <li>
                                            <Link to='/faq'>Veelgestelde vragen</Link>
                                        </li>
                                        <li>
                                            <Link to='/systemcheck'>Systeemtest</Link>
                                        </li>
                                        <li>
                                            <a href='https://ziggosport.nl/klantenservice/'>
                                                Klantenservice
                                            </a>
                                        </li>
                                        <li>
                                            <a href='https://ziggosport.nl/vacatures/'>Vacatures</a>
                                        </li>
                                    </ul>
                                </nav>
                                <nav>
                                    <Text className={$.footerMainMenuTitle}>Over Ziggo</Text>
                                    <ul>
                                        <li>
                                            <a href='https://www.ziggo.nl/?utm_source=ziggosporttotaal&utm_medium=website&utm_campaign=footerlink'>
                                                Ziggo.nl
                                            </a>
                                        </li>
                                        <li>
                                            <a href='https://www.ziggo.nl/televisie/ziggo-go?utm_source=ziggosporttotaal&utm_medium=website&utm_campaign=footerlink'>
                                                Ziggo GO app
                                            </a>
                                        </li>
                                        <li>
                                            <a href='https://www.vodafoneziggo.nl/?utm_source=ziggosporttotaal&utm_medium=website&utm_campaign=footerlink'>
                                                Ziggo Company
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div className={$.footerMainInfo}>
                                <Text className={$.footerMainMenuTitle}>Over Ziggo Sport</Text>
                                <Text>
                                    Ziggo Sport, exclusief voor Ziggo-klanten op kanaal 14, is
                                    Nederland's meest veelzijdige sportzender. Het biedt een
                                    uitgebreid aanbod van internationale en nationale sporten,
                                    waaronder voetbal uit topcompetities zoals LaLiga, Ligue 1,
                                    Serie A, FA Cup, UEFA Champions League, UEFA Nations League en
                                    WK/EK-kwalificatie voetbal.
                                </Text>
                                <Text>
                                    Daarnaast zendt het de grootste races uit, zoals Formule 1
                                    (samenvattingen), MotoGP en IndyCar. Verder bestaat het aanbod
                                    uit het beste tennis (met onder meer alle ATP 1000 en 500
                                    toernooien, WTA 1000 en 500, Wimbledon en Davis Cup en Billie
                                    Jean King Cup) en alle golftoernooien op de PGA en European
                                    Tour, inclusief de Majors en de Ryder Cup.
                                </Text>
                                <a
                                    target='_blank'
                                    rel='noreferrer'
                                    href={`${window?.APP_SETTINGS?.cmsUrl}/ontdek-ziggo-sport/`}
                                >
                                    Meer over Ziggo Sport
                                    <Icon type='arrowRight' color='primary' size={14} />
                                </a>
                                <Text className={$.footerMainMenuTitle}>Ziggo Sport Totaal GO</Text>
                                <div className={$.footerMainStore}>
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href='https://apps.apple.com/nl/app/ziggo-sport-totaal/id498487126'
                                    >
                                        <img
                                            src='/download-app-store.png'
                                            alt='App Store'
                                            height='48'
                                            width='166.5'
                                        />
                                    </a>
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href='https://play.google.com/store/apps/details?id=nl.chellomedia.sport1'
                                    >
                                        <img
                                            src='/download-play-store.png'
                                            alt='Google Play Store'
                                            height='48'
                                            width='166'
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={$.footerBottom}>
                    <div>
                        <div>
                            <nav>
                                <ul>
                                    <li>
                                        <a href='https://ziggosport.nl/privacy-statement/'>
                                            Privacy
                                        </a>
                                    </li>
                                    <li>
                                        <a href='https://ziggosport.nl/cookies/'>Cookies</a>
                                    </li>
                                    <li>
                                        <a href='https://ziggosport.nl/disclaimer/'>Disclaimer</a>
                                    </li>
                                    <li>
                                        <a href='https://ziggosport.nl/gebruikersvoorwaarden/'>
                                            Gebruikersvoorwaarden
                                        </a>
                                    </li>
                                    <li>
                                        <a href='https://ziggosport.nl/colofon/'>Colofon</a>
                                    </li>
                                </ul>
                            </nav>
                            <Text color='white'>
                                © Liberty Global Content Netherlands B.V. {new Date().getFullYear()}
                                . Alle rechten voorbehouden
                            </Text>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
