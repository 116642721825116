import { iIconProps } from '../icon';

export default function MenuIcon({ size, ...props }: iIconProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox='0 0 40 40' {...props}>
            <path d='M4 17.333C4 18.7475 4.5619 20.104 5.5621 21.1042C6.56229 22.1044 7.91885 22.6663 9.33333 22.6663H36C35.9597 21.2646 35.3849 19.9313 34.3933 18.9397C33.4017 17.9481 32.0684 17.3733 30.6667 17.333H4Z' />
            <path d='M4 6.6665C4 8.08099 4.5619 9.43755 5.5621 10.4377C6.56229 11.4379 7.91885 11.9998 9.33333 11.9998H36C35.9597 10.5981 35.3849 9.2648 34.3933 8.2732C33.4017 7.2816 32.0684 6.70677 30.6667 6.6665H4Z' />
            <path d='M4 28C4 29.4145 4.5619 30.771 5.5621 31.7712C6.56229 32.7714 7.91885 33.3333 9.33333 33.3333H36C35.9597 31.9316 35.3849 30.5983 34.3933 29.6067C33.4017 28.6151 32.0684 28.0403 30.6667 28H4Z' />
        </svg>
    );
}
