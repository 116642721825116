import { iIconProps } from '../icon';

export default function MailIcon({ size, ...props }: iIconProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox='0 0 32 32' {...props}>
            <path d='M16 16.64L26.352 8.096C26.1051 8.03948 25.8532 8.00732 25.6 8H5.52002L16 16.64Z' />
            <path d='M27.856 8.92773L21.248 14.3997L28.8 21.9517V11.1997C28.8004 10.7775 28.7171 10.3593 28.5551 9.96939C28.3931 9.57945 28.1555 9.22543 27.856 8.92773Z' />
            <path d='M3.2002 8.16016V20.8002C3.20133 21.1333 3.2609 21.4636 3.3762 21.7762L10.7522 14.4002L3.2002 8.16016Z' />
            <path d='M19.9999 15.4082L15.9999 18.7202L11.9999 15.4082L4.25586 23.1522C4.83515 23.6997 5.60282 24.0033 6.39986 24.0002H28.5919L19.9999 15.4082Z' />
        </svg>
    );
}
