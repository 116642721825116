export enum PaymentExecutedStatus {
    'AUTHORISED',
    'AUTHORISATION',
    'REFUSED',
    'CANCELLED',
    'PENDING',
    'ERROR',
    'CHARGEBACK',
    'REFUND',
}
