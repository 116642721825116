import { useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form, FormikContextType } from 'formik';
import * as Yup from 'yup';

import {
    Button,
    Checkbox,
    Error,
    FormikEffect,
    Heading,
    Input,
    RegisterForm,
    Select,
    Message,
} from '~components';
import { iAuth } from '~hooks/use-auth';
import { useProviders, useProviderForm } from '~hooks/use-providers';

import $ from './login-form.scss';

interface iProps {
    login: iAuth['login'];
    startOauthLogin: iAuth['startOauthLogin'];
}
const LoginSchema = Yup.object().shape({
    provider: Yup.string().required('Verplicht'),
    username: Yup.string().required('Verplicht'),
    password: Yup.string().required('Verplicht'),
    rememberPassword: Yup.boolean(),
});

function LoginForm({ login, startOauthLogin }: iProps) {
    const [providerId, setProviderId] = useState(1);
    const [loginError, setLoginError] = useState<string | undefined>();

    const { data, error, loading } = useProviders();
    const externalUrl = useMemo(
        () => data?.providers.find((provider) => provider.id === providerId)?.externalUrl,
        [data, providerId],
    );
    const { data: providerForm } = useProviderForm(providerId, !!externalUrl);

    const providers = useMemo(
        () =>
            data?.providers?.map((provider) => ({
                value: provider.id,
                label: provider.name,
            })),
        [data],
    );

    const selectedProvider = data?.providers.find((provider) => provider.id === providerId);

    const renderLoginError = useMemo(() => {
        if (!loginError) return null;
        if (loginError === 'Invalid credentials.') {
            return <Message.Error message='E-mailadres en/of wachtwoord onbekend' />;
        }

        return <Message.Error message={loginError} />;
    }, [loginError]);

    const renderLogin = useMemo(() => {
        const passwordHidden =
            typeof providerForm?.passwordHidden === 'boolean' ? providerForm?.passwordHidden : true;

        if (selectedProvider?.externalUrl) {
            return (
                <Button
                    onClick={() =>
                        startOauthLogin(selectedProvider?.externalUrl, selectedProvider?.name)
                    }
                    label='Inloggen'
                />
            );
        }

        return (
            <>
                {renderLoginError}
                <Input
                    name='username'
                    type='text'
                    label={providerForm?.usernameTitle || 'E-mailadres'}
                    placeholder={
                        providerForm?.usernamePlaceholder ||
                        providerForm?.usernameTitle ||
                        'E-mailadres'
                    }
                    autoComplete='username'
                />
                <Input
                    name='password'
                    type={!passwordHidden ? 'text' : 'password'}
                    label={providerForm?.passwordTitle || 'Wachtwoord'}
                    placeholder={
                        providerForm?.passwordPlaceholder ||
                        providerForm?.passwordTitle ||
                        'Wachtwoord'
                    }
                    autoComplete='password'
                />
                <Checkbox name='rememberPassword' label='Onthoud mij' />
                <Button type='submit' label='Inloggen' />
            </>
        );
    }, [selectedProvider, providerForm, startOauthLogin, renderLoginError]);

    const handleChange = useCallback(
        ({ values }: FormikContextType<any>) => {
            if (providerId !== values.provider) {
                setProviderId(Number(values.provider));
            }
        },
        [providerId],
    );

    const handleLogin = ({ username, password }: { username: string; password: string }) => {
        if (!selectedProvider) return;

        login(username, password, selectedProvider?.id, selectedProvider?.name).catch(
            (err: Error) => {
                setLoginError(err.message);
            },
        );
    };

    const defaultProvider = useMemo(
        () => data?.providers?.find((provider) => provider.default)?.id,
        [data],
    );

    if (loading) return null;
    if (error) return <Error error={error} />;

    return (
        <div className={$.container}>
            <div className={$.form}>
                <Heading>Inloggen</Heading>
                <Formik
                    initialValues={{
                        provider: defaultProvider ?? 1,
                        username: '',
                        password: '',
                        rememberPassword: true,
                    }}
                    validationSchema={LoginSchema}
                    onSubmit={handleLogin}
                >
                    <Form>
                        <FormikEffect onChange={handleChange} />
                        <Select
                            name='provider'
                            label='Selecteer provider'
                            placeholder='Selecteer provider'
                            options={providers}
                        />
                        {renderLogin}
                    </Form>
                </Formik>
                <div className={$.forgot}>
                    <Link to='/forgot-password'>Wachtwoord vergeten?</Link>
                </div>
            </div>
            <RegisterForm className={$.form} />
        </div>
    );
}

export default LoginForm;
