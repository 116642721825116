import { forwardRef } from 'react';
import cx from 'clsx';

import mapPropsToStyles from '~utils/map-props-to-styles';

import $ from './button.scss';

interface iProps extends React.ComponentPropsWithoutRef<'button'> {
    label: string | JSX.Element;
    size?: 'smaller' | 'small' | 'medium';
    color?: 'primary' | 'secondary' | 'black' | 'white' | 'transparent';
}

export type ButtonProps = iProps;

export const Button = forwardRef<HTMLButtonElement, iProps>(
    ({ label, className, size = 'small', color = 'primary', type = 'button', ...props }, ref) => {
        return (
            <button
                className={cx(mapPropsToStyles({ size, color }, $), className)}
                type={type}
                ref={ref}
                {...props}
            >
                {label}
            </button>
        );
    },
);
