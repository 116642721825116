import { iIconProps } from '../icon';

export default function TVIcon({ size, ...props }: iIconProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox='0 0 40 40' {...props}>
            <path d='M30 7H4V21C4 22.5913 4.63214 24.1174 5.75736 25.2426C6.88258 26.3679 8.4087 27 10 27H36V13C36 11.4087 35.3679 9.88258 34.2426 8.75736C33.1174 7.63214 31.5913 7 30 7ZM32 23H10C9.46957 23 8.96086 22.7893 8.58579 22.4142C8.21071 22.0391 8 21.5304 8 21V11H30C30.5304 11 31.0391 11.2107 31.4142 11.5858C31.7893 11.9609 32 12.4696 32 13V23Z' />
            <path d='M16 29H24C25.0609 29 26.0783 29.4214 26.8284 30.1716C27.5786 30.9217 28 31.9391 28 33H12C12 31.9391 12.4214 30.9217 13.1716 30.1716C13.9217 29.4214 14.9391 29 16 29Z' />
        </svg>
    );
}
