import { lazy, ComponentType, FC, LazyExoticComponent } from 'react';

const AccountView = lazy(() => import('./views/account'));
const ActivateView = lazy(() => import('./views/activate'));
const FaqView = lazy(() => import('./views/faq'));
const ForgotPasswordView = lazy(() => import('./views/forgot-password'));
const ResetPasswordView = lazy(() => import('./views/reset-password'));
const LiveView = lazy(() => import('./views/live'));
const LiveItemView = lazy(() => import('./views/live-item'));
const LoginView = lazy(() => import('./views/login'));
const MissedItemView = lazy(() => import('./views/missed-item'));
const MissedOverviewView = lazy(() => import('./views/missed-overview'));
const OAuthView = lazy(() => import('./views/oauth'));
const OAuthLandingView = lazy(() => import('./views/oauth-landing-view'));
const PayPerViewView = lazy(() => import('./views/pay-per-view'));
const PayPerViewItemView = lazy(() => import('./views/pay-per-view-item'));
const PricingView = lazy(() => import('./views/pricing'));
const SubscriptionRequiredView = lazy(() => import('./views/subscription-required'));
const SystemcheckView = lazy(() => import('./views/systemcheck'));
const UefaItemView = lazy(() => import('./views/uefa-item'));
const ConnectAccountView = lazy(() => import('./views/connect-account-view'));

export interface iRoute {
    path: string;
    exact?: boolean;
    protected?: boolean;
    component: LazyExoticComponent<ComponentType<FC>>;
}

export const paths = {
    account: '/account',
    activate: '/activate',
    connectAccount: '/account/connect',
    faq: '/faq',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    live: '/live',
    liveItem: '/live/:id',
    login: '/login',
    missedOverview: '/missed',
    missedItem: '/missed/:id',
    oauth: '/provider/:provider/code',
    oauthLanding: '/provider/:provider/landing',
    payPerView: '/payperview',
    payPerViewItem: '/payperview/:id',
    uefaItem: '/uefa/:uefaStreamId',
    pricing: '/',
    subscriptionRequired: '/subscription-required',
    systemcheck: '/systemcheck',
};

export const routes: iRoute[] = [
    {
        path: paths.account,
        component: AccountView,
        protected: true,
    },
    {
        path: paths.activate,
        component: ActivateView,
    },
    {
        path: paths.connectAccount,
        component: ConnectAccountView,
    },
    {
        path: paths.faq,
        component: FaqView,
    },
    {
        path: paths.forgotPassword,
        component: ForgotPasswordView,
    },
    {
        path: paths.resetPassword,
        component: ResetPasswordView,
    },
    {
        path: paths.live,
        component: LiveView,
        protected: true,
    },
    {
        path: paths.liveItem,
        component: LiveItemView,
        protected: true,
    },
    {
        path: paths.login,
        component: LoginView,
    },
    {
        path: paths.missedOverview,
        component: MissedOverviewView,
    },
    {
        path: paths.missedItem,
        component: MissedItemView,
    },
    {
        path: paths.oauth,
        component: OAuthView,
    },
    {
        path: paths.oauthLanding,
        component: OAuthLandingView,
    },
    {
        path: paths.payPerView,
        component: PayPerViewView,
    },
    {
        path: paths.payPerViewItem,
        component: PayPerViewItemView,
    },
    {
        path: paths.uefaItem,
        component: UefaItemView,
    },
    {
        path: paths.pricing,
        component: PricingView,
    },
    {
        path: paths.subscriptionRequired,
        component: SubscriptionRequiredView,
    },
    {
        path: paths.systemcheck,
        component: SystemcheckView,
    },
];
