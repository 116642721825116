import { Field, ErrorMessage, FieldAttributes, FormikState } from 'formik';
import cx from 'clsx';

import $ from './select.scss';
import { Icon } from '../icon';

interface iSelectOption {
    value: string;
    label: string;
}

interface iProps {
    label: string | undefined;
    options: iSelectOption[];
}

function SelectComponent({
    field,
    form: { touched, errors },
    label,
    options,
    ...props
}: iProps & {
    field: FieldAttributes<any>;
    form: FormikState<any>;
}): JSX.Element {
    const error = touched[field.name] && errors[field.name];

    return (
        <div className={$.wrapper}>
            {label && (
                <label className={$.label} htmlFor={field.name}>
                    {label}
                </label>
            )}
            <div className={$.container}>
                <select className={cx($.root, error && $.error)} {...field} {...props}>
                    {options.map((option) => (
                        <option
                            className={$.option}
                            key={option.value}
                            value={option.value}
                            // selected={value === option.value}
                        >
                            {option.label}
                        </option>
                    ))}
                </select>
                <Icon type='chevronDown' size={8} color='gray-light' />
            </div>
            <ErrorMessage name={field.name}>
                {(msg) => <div className={$.errorMessage}>{msg}</div>}
            </ErrorMessage>
        </div>
    );
}

export function Select(props: FieldAttributes<any> & iProps): JSX.Element {
    return <Field component={SelectComponent} type='select' {...props} />;
}
