import cx from 'clsx';

import { Text } from '~components';
import { iEpgLiveChannel } from '~models';

import $ from './channel-select.scss';

interface iProps {
    active?: boolean;
    onSelect: (channelId: number) => void;
    channel: iEpgLiveChannel;
}

export function ChannelSelect({ channel, active, onSelect }: iProps): JSX.Element {
    return (
        <div
            className={cx($.container, active && $.active)}
            onClick={() => onSelect(channel.channelId)}
            onKeyPress={() => onSelect(channel.channelId)}
            role='button'
            tabIndex={0}
        >
            {channel.channelImageLight ? (
                <>
                    <img
                        className={$.logo}
                        src={channel.channelImageLight}
                        alt={channel.channelName}
                        height={24}
                    />
                    <Text className={$.name}>{channel.channelName}</Text>
                </>
            ) : (
                <Text className={$.fallbackTitle}>{channel.channelName}</Text>
            )}
        </div>
    );
}
