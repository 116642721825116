import React, { useEffect } from 'react';

export function useOutside(
    ref: React.RefObject<HTMLElement | undefined>,
    onClick?: (e: React.MouseEvent<HTMLDocument>) => void,
): void {
    useEffect(() => {
        function handleClickOutside(e: Event): void {
            if (ref.current && !ref.current.contains(e.target as Node)) onClick?.(e as any);
        }

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchend', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchend', handleClickOutside);
        };
    }, [ref, onClick]);
}
