import api from '~source/core/services/api';
import { transformProviders } from '~source/core/transformers/providers';
import { transformProviderForm } from '~source/core/transformers/provider-form';
import { useAsync } from '~hooks/use-async';

const AUTH_PROVIDER_FORM_ENDPOINT = 'providers/{id}/form';
const AUTH_PROVIDERS_ENDPOINT = 'providers';

export function useProviderForm(providerId: number, isExternalProvider: boolean) {
    const [data, error, loading, { startReload }] = useAsync(
        async (signal: AbortSignal) => {
            if (isExternalProvider) return null;

            const rawProviderForm = await api.get(AUTH_PROVIDER_FORM_ENDPOINT, {
                signal,
                params: { id: providerId },
            });

            return transformProviderForm(rawProviderForm);
        },
        null,
        [providerId],
    );

    return { data, error, loading, startReload };
}

export function useProviders() {
    const [data, error, loading, { startReload }] = useAsync(
        async (signal: AbortSignal) => {
            const rawProviders = await api.get(AUTH_PROVIDERS_ENDPOINT, {
                signal,
                headers: {
                    'X-Web': 'true',
                },
            });

            return transformProviders(rawProviders);
        },
        null,
        [],
    );

    return { data, error, loading, startReload };
}
