import { Text, Button } from '~components';
import { iUserDevice } from '~models';

import format from '~utils/format-date';

import $ from './device.scss';

interface iProps {
    device: iUserDevice;
    onDelete: () => void;
}

export function Device({ device, onDelete }: iProps): JSX.Element {
    const renderDate = () => {
        if (!device.lastUpdated) return null;

        return <Text>laatst gebruikt op {format(device.lastUpdated, 'dd-MM-yyyy HH:mm')}</Text>;
    };

    return (
        <div className={$.container}>
            <div className={$.title}>
                <Text color='white'>{device.deviceTypeName}</Text>
                &nbsp;
                {renderDate()}
            </div>
            <div className={$.link}>
                {device.canBeDeleted && <Button label='Verwijder' onClick={onDelete} />}
            </div>
        </div>
    );
}
