import { Field, ErrorMessage, FieldAttributes, FormikState } from 'formik';
import cx from 'clsx';

import $ from './checkbox.scss';

interface iProps {
    label: string;
}

function CheckboxComponent({
    field,
    form: { touched, errors },
    label,
    showError = true,
    ...props
}: iProps & {
    showError?: boolean;
    field: FieldAttributes<any>;
    form: FormikState<any>;
}): JSX.Element {
    const error = touched[field.name] && errors[field.name];

    return (
        <div className={$.wrapper}>
            <div className={$.container}>
                <input
                    className={cx($.checkbox, error && $.error)}
                    id={field.name}
                    type='checkbox'
                    checked={field.value}
                    {...field}
                    {...props}
                />
                <label className={$.label} htmlFor={field.name}>
                    {label}
                </label>
            </div>
            {showError && (
                <ErrorMessage name={field.name}>
                    {(msg) => <div className={$.errorMessage}>{msg}</div>}
                </ErrorMessage>
            )}
        </div>
    );
}

export function Checkbox(props: FieldAttributes<any> & iProps): JSX.Element {
    return <Field component={CheckboxComponent} {...props} />;
}
