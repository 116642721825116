import { iBrowserDevice } from '~source/core/models';

const getBrowserName = (browserInfo: jwplayer.Browser): iBrowserDevice => {
    if (browserInfo.chrome) {
        return 'chrome';
    }
    if (browserInfo.edge) {
        return 'edge';
    }
    if (browserInfo.firefox) {
        return 'firefox';
    }
    if (browserInfo.safari) {
        return 'safari';
    }
    if (browserInfo.ie) {
        return 'ie';
    }

    return 'unknown';
};

export const getBrowserInfo = () => {
    const jwTestId = 'JW_TEST_PLAYER';
    const jwEl = document.createElement('div');
    jwEl.id = jwTestId;
    jwEl.hidden = true;
    document.body.appendChild(jwEl);
    const environment = window.jwplayer(jwTestId).getEnvironment();
    window.jwplayer(jwTestId).remove();
    jwEl.remove();

    const browserName = getBrowserName(environment.Browser);
    const browserVersion: string = environment.Browser.version.version;
    const isMobile: boolean = environment.OS.mobile;

    return {
        browserName,
        browserVersion,
        isMobile,
    };
};
