import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format, isFuture } from 'date-fns';
import fallbackImage from '../../../../../public/fallback.png';
import OTTFallbackImage from '../../../../../public/Base_Orange_Mobile.png';
import clamp from '~source/utils/clamp';
import { Icon, Text } from '~components';
import { FutureModal } from '../../molecules/modal/FutureModal';

import $ from './item.scss';
import { OTTTitle } from '../../atoms/ott-title/OTTTile';

interface iProps {
    title: React.ReactNode;
    epgOnly?: boolean;
    startTime?: Date;
    endTime?: Date;
    imageUrl?: string | null;
    link?: string;
    ott?: boolean;
    subTitle?: string;
    duration?: string;
}

export function Item({
    title,
    imageUrl,
    link,
    ott,
    subTitle,
    startTime,
    endTime,
    duration,
    epgOnly,
}: iProps): JSX.Element {
    const history = useHistory();
    const [innerImageUrl, setInnerImageUrl] = useState(imageUrl);
    const [showModal, setShowModal] = useState(false);

    const now = new Date();

    let progressDuration = 0;
    let progress = 0;

    if (startTime && endTime) {
        progressDuration = endTime.getTime() - startTime.getTime();
        progress = now.getTime() - startTime.getTime();
    }

    const percentage = clamp(
        progressDuration && progress ? (100 / progressDuration) * progress : 0,
        0,
        100,
    );

    useEffect(() => {
        if (ott) {
            setInnerImageUrl(imageUrl || OTTFallbackImage);
        } else {
            setInnerImageUrl(imageUrl || fallbackImage);
        }
    }, [imageUrl, ott]);

    const setFallback = useCallback(() => {
        setInnerImageUrl(ott ? OTTFallbackImage : fallbackImage);
    }, [ott]);

    function handleClick() {
        // main channels should always open the player
        if (!epgOnly && !ott) {
            history.push(link);
        } else if (isFuture(startTime!) || !startTime) {
            setShowModal(true);
        } else if (link) {
            history.push(link);
        }
    }

    return (
        <>
            <div
                className={$.container}
                onClick={() => handleClick()}
                onKeyPress={() => handleClick()}
                role='button'
                tabIndex={0}
            >
                <div className={$.imageContainer}>
                    {innerImageUrl && (
                        <img className={$.image} src={innerImageUrl} alt='' onError={setFallback} />
                    )}

                    {ott && !imageUrl && <OTTTitle title={subTitle} date={startTime} />}

                    <div className={$.iconContainer}>
                        <Icon type='play' color='white' size={16} className={$.icon} />
                    </div>

                    {duration && (
                        <div className={$.duration}>
                            <Text color='white' className={$.durationText}>
                                {duration}
                            </Text>
                        </div>
                    )}

                    {startTime && endTime && (
                        <div className={$.time}>
                            <span>{format(startTime, 'HH:mm')} - </span>
                            <span>{format(endTime, 'HH:mm')}</span>
                        </div>
                    )}

                    {startTime && !isFuture(startTime) && (
                        <div className={$.progressContainer}>
                            <div className={$.progressBar} style={{ width: `${percentage}%` }} />
                        </div>
                    )}
                </div>

                <div className={$.title}>{title}</div>
            </div>

            {showModal && <FutureModal close={() => setShowModal(false)} />}
        </>
    );
}
