import { Text } from '~components';

interface iProps {
    message: string;
    className?: string;
}

export function Message({ message, className }: iProps) {
    return (
        <Text color='primary' className={className}>
            {message}
        </Text>
    );
}

export function ErrorMessage({ message, className }: iProps) {
    return (
        <Text color='error' className={className}>
            {message}
        </Text>
    );
}

Message.Error = ErrorMessage;
