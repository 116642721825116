import Slider from 'react-slick';
import $ from './channel-slider.scss';

export function ChannelSlider({ children }): JSX.Element {
    return (
        <div className={$.container}>
            <Slider
                dots={false}
                infinite={false}
                speed={500}
                slidesToShow={5}
                slidesToScroll={5}
                initialSlide={0}
                responsive={[
                    {
                        breakpoint: 1200,
                        settings: { slidesToShow: 4, slidesToScroll: 4 },
                    },
                    {
                        breakpoint: 950,
                        settings: { slidesToShow: 3, slidesToScroll: 3 },
                    },
                    {
                        breakpoint: 700,
                        settings: { slidesToShow: 2, slidesToScroll: 2 },
                    },
                    {
                        breakpoint: 500,
                        settings: { slidesToShow: 1, slidesToScroll: 1 },
                    },
                ]}
            >
                {children}
            </Slider>
        </div>
    );
}
