import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

import { Heading, Text } from '~components';

import $ from './error.scss';

interface iProps {
    error?: Error;
}

export function Error({ error }: iProps): JSX.Element {
    const { pathname } = useLocation();

    useEffect(() => {
        ReactGA.event({
            category: 'page_error',
            action: pathname,
            label: error?.message,
        });
    }, [pathname, error]);

    const heading = error?.message ?? 'Er is een fout opgetreden';

    return (
        <div className={$.container}>
            <Heading>{heading}</Heading>
            <Text>Probeer het later nog een keer.</Text>
        </div>
    );
}
