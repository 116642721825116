import { Suspense, useEffect } from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

import { Route, Header } from '~components';

import { AuthProvider } from '~hooks/use-auth';
import { InitProvider } from '~hooks/use-init';

import { routes } from './routes';
import './styles.scss';

function ScrollToTop(): null {
    const location = useLocation();

    useEffect(() => {
        document.body.scrollTo(0, 0);

        if (!location.hash) return;

        setTimeout(() => {
            const { top } = document.querySelector(location.hash)?.getBoundingClientRect() ?? {};
            if (!top) return;

            document.body.scrollTo({
                top: top - document.querySelector('header')!.getBoundingClientRect().height,
                left: 0,
                behavior: 'smooth',
            });
        }, 1000);
    }, [location.key, location.hash]);

    return null;
}

// Here we place all higher level contexts
function App(): JSX.Element {
    if (window?.APP_SETTINGS?.gaTrackingCode) {
        ReactGA.initialize(window.APP_SETTINGS.gaTrackingCode, {
            debug: process.env.NODE_ENV === 'development',
        });
    }

    const location = useLocation();
    const { pathname } = location;
    useEffect(() => {
        ReactGA.pageview(pathname);
    }, [pathname]);

    return (
        <AuthProvider>
            <InitProvider>
                <Header />
                <Suspense fallback={null}>
                    <ScrollToTop />
                    <Switch>
                        {routes.map((route) => (
                            <Route
                                key={route.path}
                                exact={route.exact !== false}
                                path={route.path}
                                component={route.component}
                                protected={route.protected}
                            />
                        ))}

                        <Redirect from='*' to='/' />
                    </Switch>
                </Suspense>
            </InitProvider>
        </AuthProvider>
    );
}

export default App;
