import { iResponseInit, iInit } from '~source/core/models';

export function transformInit(value: iResponseInit): iInit {
    const serverTime = value.serverTime * 1000; // s to ms
    const serverTimeDelta = serverTime - Date.now();

    return {
        serverTime,
        serverTimeDelta,
        updateRequired: value.updateRequired,
        updatePromptInterval: value.updatePromptInterval,
        appIsBlockedMessage: value.appIsBlockedMessage,
        modalDialog: value.modalDialog,
        proxyIsBlockedMessage: value.proxyIsBlockedMessage,
        geoPurchaseAllowed: value.geoPurchaseAllowed,
        geoPlaybackAllowed: value.geoPlaybackAllowed,
        geoMessage: value.geoMessage,
        hasSubscription: value.hasSubscription,
        subscriptionValidity: value.subscriptionValidity,
        convivaUserId: value.convivaUserId,
        isLocalUser: value.isLocalUser,
    };
}
