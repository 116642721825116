import { Container, Footer } from '~components';
import { FooterProps } from '~components/organisms/footer/footer';

import $ from './layout.scss';

interface iProps extends React.HTMLAttributes<HTMLDivElement> {
    body?: boolean;
    pre?: FooterProps['pre'];
    showBackground?: boolean;
}

export function Layout({
    children,
    body = true,
    pre = true,
    showBackground = false,
}: iProps): JSX.Element {
    return (
        <main>
            {showBackground && <div className={$.background} />}
            {body ? <Container>{children}</Container> : children}
            <Footer pre={pre} />
        </main>
    );
}
