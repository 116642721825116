import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Text, Message, Button } from '~components';
import { iUserTransaction } from '~models';
import { useSettings } from '~hooks/use-settings';
import format from '~utils/format-date';

import $ from './transaction.scss';
import { isWithinInterval } from '~source/utils/is-within-interval';

interface iProps {
    transaction: iUserTransaction;
}

export function Transaction({ transaction }: iProps): JSX.Element {
    const history = useHistory();
    const settings = useSettings();
    const getCurrentDate = useCallback(() => {
        return new Date(Date.now() + (settings?.serverTimeDelta || 0));
    }, [settings]);

    const onTransactionClick = useCallback(() => {
        if (!transaction.paid || !transaction.videoUrl) return;

        const path = transaction.videoUrl.replace(window.location.origin, '');
        history.push(path);
    }, [history, transaction]);

    const renderDate = () => {
        // Pay per view items should only show the purchase date
        if (Number.isInteger(transaction.videoId)) {
            return <Text>({format(transaction.created, 'dd-MM-yyyy')})</Text>;
        }

        if (transaction.validFrom && transaction.validUntil) {
            return (
                <Text>
                    ({format(transaction.validFrom, 'dd-MM-yyyy')} tot{' '}
                    {format(transaction.validUntil, 'dd-MM-yyyy')})
                </Text>
            );
        }
        return <Text>({format(transaction.validFrom, 'dd-MM-yyyy')})</Text>;
    };

    const showLink = useMemo(() => {
        const isActive = isWithinInterval(
            transaction.validFrom,
            transaction.validUntil,
            getCurrentDate(),
        );

        return transaction.paid && transaction.videoUrl && isActive;
    }, [getCurrentDate, transaction]);

    return (
        <div className={$.container}>
            <div className={$.title}>
                <Text color='white'>{transaction.title}</Text>
                &nbsp;
                {renderDate()}
            </div>
            <div className={$.paid}>
                {transaction.paid ? (
                    <Text color='primary'>betaald</Text>
                ) : (
                    <Message.Error message='niet betaald' />
                )}
            </div>
            <div className={$.link}>
                {showLink && <Button label='bekijken' onClick={onTransactionClick} />}
            </div>
        </div>
    );
}
