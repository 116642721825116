/**
 * Example usage:
 *
 *  const [result, error, loading] = useAsync(() => api.get(id), null, [id]);
 *
 * Example usage with AbortSignal:
 *
 * const [res, err, busy] = useAsync(signal => api.get(id, { signal }), null, [id]);
 *
 * When the dependencies change the state will be reset to no-result, no-error, loading:true.
 * The previous promise can no longer affect the state and a new promise is created.
 * Using the AbortSignal is just a performance optimalisation and doesn't affect the behavior.
 */

import { useRef, DependencyList, useEffect, useState, useCallback } from 'react';
/**
 * Hook for managing async tasks that need to execute based on the dependencylist.
 * Provides the result, error and loading states
 *
 * @param fn Function that creates the promise with the result (or error)
 * @param placeholder The value of the result when the promise is not yet resolved
 *                    (Similar to initialValue but also is set when the deps change)
 * @param deps The dependencylist
 */
export function useAsync<T>(
    fn: (signal: AbortSignal) => Promise<T>,
    placeholder: T,
    deps: DependencyList,
): [T, Error | null, boolean, { startReload?: () => void }] {
    const loadingStateRef = useRef({
        result: placeholder,
        error: null,
        loading: true,
    });
    const [isReload, setIsReload] = useState<boolean | null>(null);
    const [{ result, error, loading }, setState] = useState<{
        result: T;
        error: Error | null;
        loading: boolean;
    }>(loadingStateRef.current);

    useEffect(() => {
        const controller = new AbortController();
        setState(loadingStateRef.current);
        if (isReload === null) return () => controller.abort();
        try {
            fn(controller.signal)
                .then((value) => {
                    if (controller.signal.aborted === false) {
                        setState({
                            result: value,
                            error: null,
                            loading: false,
                        });
                    }
                })
                .catch((err) => {
                    if (controller.signal.aborted === false) {
                        setState({
                            result: loadingStateRef.current.result,
                            error: err,
                            loading: false,
                        });
                    }
                });
        } catch (err) {
            // The fn failed (synchronously) to create a promise
            setState({
                result: loadingStateRef.current.result,
                error: err,
                loading: false,
            });
            controller.abort(); // cancel tasks that started before the error occurred
        }
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReload]);

    useEffect(() => {
        setIsReload((v) => !v);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    const startReload = useCallback((): void => {
        setIsReload((v) => !v);
    }, []);

    return [
        result,
        error,
        loading,
        {
            startReload,
        },
    ];
}
