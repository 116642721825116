import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Item } from '~components';
import { iEpgChannel, iEpgLiveChannel, iEpgItem, iStreams } from '~models';

import $ from './epg-item.scss';
import { ItemTitle } from '../../atoms/item-title/ItemTitle';

interface iProps {
    channel: iEpgChannel | iEpgLiveChannel;
    item?: iEpgItem;
    streams?: iStreams | null;
}

const getCacheBuster = () => {
    const now = new Date();
    const seconds = Math.floor(now.getSeconds() / 10);
    return `${now.getFullYear()}${now.getMonth()}${now.getDate()}${now.getHours()}${now.getMinutes()}${seconds}`;
};

export function EpgItem({ channel, item, streams }: iProps): JSX.Element {
    const link = () => {
        const stream = streams?.streams.find((s) => s.channelId === channel.channelId);

        if (stream?.uefaId && stream.uefaId.length > 0) return `uefa/${stream.id}`;

        return `live/${channel.channelId}`;
    };

    const renderSubtitle = () => {
        if (!item) return 'Er zijn vandaag op deze zender geen live uitzendingen.';

        return (
            <div className={$.subtitle}>
                <div className={$.subtitleContent}>
                    {channel.multiDayEpg && (
                        <span className={$.date}>{format(item.startTime, 'EEEEEE dd LLL')}</span>
                    )}

                    {item.live && <span className={$.live}>LIVE</span>}
                    {item?.name}
                </div>
            </div>
        );
    };

    const [buster, setBuster] = useState(getCacheBuster());

    useEffect(() => {
        const busterInterval = setInterval(() => {
            setBuster(getCacheBuster());
        }, 1000);

        return () => {
            clearInterval(busterInterval);
        };
    }, []);

    return (
        <div>
            <Item
                epgOnly={channel.epgOnly}
                ott={channel.multiDayEpg}
                link={link()}
                imageUrl={
                    channel.multiDayEpg
                        ? channel.channelScreenshot
                        : `${channel.channelScreenshot}?bust=${buster}`
                }
                title={
                    <>
                        <ItemTitle name={channel.channelName} image={channel.channelImageLight} />
                        {renderSubtitle()}
                    </>
                }
                subTitle={item?.name}
                startTime={item?.startTime}
                endTime={item?.endTime}
            />
        </div>
    );
}
