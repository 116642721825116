import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { AxiosError } from 'axios';
import ReactGA from 'react-ga';

import { transformAuthTokens } from '~source/core/transformers/auth';
import { transformUser } from '~source/core/transformers/user';

import { iUser, iAuthTokens, iResponseAuthTokens } from '~models';
import api from '~source/core/services/api';
import { readRefreshToken, writeRefreshToken } from '~utils/tokens';

const ACTIVATION_ENPOINT = 'activation';
const CANCEL_CONTRACT_ENDPOINT = 'user/contract';
const CHANGE_PASSWORD_ENDPOINT = 'change-password';
const CHECK_REGISTRATION_ENDPOINT = 'user/check/kpn';
const CONNECT_ENDPOINT = 'user/connect/kpn';
const DELETE_DEVICE_ENDPOINT = 'device/{id}';
const FORGOT_PASSWORD_ENDPOINT = 'forgot-password';
const LOGIN_ENDPOINT = 'authorization/provider/{id}';
const LOGOUT_ENDPOINT = 'authorization/logout';
const OAUTH_LOGIN_ENDPOINT = 'authorization/provider/oauth/{id}';
const REFRESH_ENDPOINT = 'authorization/refresh';
const REGISTER_ENDPOINT = 'register';
const RESET_PASSWORD_ENDPOINT = 'reset-password';
const USER_ENDPOINT = 'user';

export interface iAuth {
    activateAccount: (code: string, onSuccess?: () => void) => Promise<void>;
    authToken?: string;
    cancelContract: (onSuccess?: () => void) => Promise<void>;
    changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
    checkCanRegister: (
        msid: string,
        providerKey: string,
        session: string,
        onSuccess?: () => void,
    ) => Promise<void>;
    connectAccount: (
        msid: string,
        providerKey: string,
        session: string,
        onSuccess?: () => void,
    ) => Promise<void>;
    deleteDevice: (deviceId: number) => Promise<void>;
    error?: Error;
    forgotPassword: (email: string) => Promise<void>;
    hasExternalProvider?: boolean;
    isAuthorized: boolean;
    isInitialising: boolean;
    login: (
        username: string,
        password: string,
        providerId: number,
        providerName: string,
    ) => Promise<void>;
    logout: () => Promise<void>;
    oauthLogin: (provider: string, code: string, state: string) => Promise<void>;
    refreshUser: () => Promise<void>;
    register: (
        email: string,
        password: string,
        newsletter: boolean,
        extraData?: Record<string, string>,
        onSuccess?: () => void,
    ) => Promise<any>;
    resetPassword: (code: string, password: string, onSuccess?: () => void) => Promise<any>;
    startOauthLogin: (url: string, providerName: string) => void;
    user?: iUser;
}

const WEB_LOGIN_SESSION_KEY = 'ZST_WEB_LOGIN';

let authToken: iAuthTokens['authToken'];
let refreshToken: iAuthTokens['refreshToken'] = readRefreshToken();
const tokenListeners: Function[] = [];

function setTokens(data: iAuthTokens) {
    authToken = data.authToken;
    refreshToken = data.refreshToken;
    writeRefreshToken(data.refreshToken);
    tokenListeners.forEach((fn) => fn(authToken, refreshToken));
}

export const tokens = {
    setTokens,
    authToken: () => {
        return authToken;
    },
    refreshToken: () => {
        return refreshToken;
    },
};

const authContext = createContext<iAuth>({
    activateAccount: async () => undefined,
    authToken: undefined,
    cancelContract: async () => undefined,
    changePassword: async () => undefined,
    checkCanRegister: async () => undefined,
    connectAccount: async () => undefined,
    deleteDevice: async () => undefined,
    error: undefined,
    forgotPassword: async () => undefined,
    hasExternalProvider: undefined,
    isAuthorized: false,
    isInitialising: false,
    login: async () => undefined,
    logout: async () => undefined,
    oauthLogin: async () => undefined,
    refreshUser: async () => undefined,
    register: async () => undefined,
    resetPassword: async () => undefined,
    startOauthLogin: () => undefined,
    user: undefined,
});

export const useAuth = () => useContext(authContext);

const useProvideAuth = (): iAuth => {
    const [isInitialising, setIsInitialising] = useState<iAuth['isInitialising']>(true);
    const [isAuthorized, setIsAuthorized] = useState<iAuth['isAuthorized']>(false);
    const [hasExternalProvider, setHasExternalProvider] = useState<iAuth['hasExternalProvider']>();
    const [, forceUpdate] = useState(false);
    const [user, setUser] = useState<iAuth['user']>();
    const [error, setError] = useState<iAuth['error']>();

    const catchError = (err: AxiosError) => {
        const catchedError = new Error((err?.response?.data as any)?.message || err?.message);
        setError(catchedError);
        throw catchedError;
    };

    useEffect(() => {
        const index = tokenListeners.push(() => forceUpdate((v) => !v)) - 1;
        return () => {
            delete tokenListeners[index];
        };
    }, []);

    useEffect(() => {
        setHasExternalProvider(user?.provider.external);
    }, [user]);

    const fetchUser = useCallback(async (token?: string) => {
        try {
            const rawUser = await api.get(USER_ENDPOINT, {
                authToken: token ?? tokens.authToken(),
            });

            setIsAuthorized(true);
            setUser(transformUser(rawUser));
        } catch (err) {
            catchError(err);
        }
    }, []);

    const refreshUser = useCallback(async () => {
        await fetchUser();
    }, [fetchUser]);

    const activateAccount = useCallback(async (code: string, onSuccess?: () => void) => {
        try {
            await api.post(ACTIVATION_ENPOINT, {
                code,
            });
            if (onSuccess) onSuccess();
        } catch (err) {
            catchError(err);
        }
    }, []);

    const handleTokenResponse = useCallback(
        (rawTokens: iResponseAuthTokens) => {
            const tokenTransform = transformAuthTokens(rawTokens);
            tokens.setTokens(tokenTransform);
            fetchUser(tokenTransform.authToken);
        },
        [fetchUser],
    );

    const directToApp = useCallback((rawTokens: iResponseAuthTokens) => {
        const tokenTransform = transformAuthTokens(rawTokens);
        window.location.href = `zst://loginfinished/${tokenTransform.authToken}/${tokenTransform.refreshToken}`;
    }, []);

    const login = useCallback(
        async (username: string, password: string, providerId: number, providerName: string) => {
            ReactGA.event({
                category: 'login_start',
                action: providerName,
            });

            try {
                const rawTokens = await api.post(
                    LOGIN_ENDPOINT,
                    {
                        username,
                        password,
                    },
                    { params: { id: providerId } },
                );

                handleTokenResponse(rawTokens);
                ReactGA.event({
                    category: 'login_success',
                    action: providerName,
                });
            } catch (err) {
                ReactGA.event({
                    category: 'login_error',
                    action: providerName,
                    label: err?.response?.data?.message || err.message,
                });
                catchError(err);
            }
        },
        [handleTokenResponse],
    );

    const startOauthLogin = useCallback((url: string, providerName: string) => {
        ReactGA.event({
            category: 'login_start',
            action: providerName,
        });
        sessionStorage.setItem(WEB_LOGIN_SESSION_KEY, 'true');
        window.location.href = url;
    }, []);

    const oauthLogin = useCallback(
        async (provider: string, code: string, state: string) => {
            try {
                const rawTokens = await api.post(
                    OAUTH_LOGIN_ENDPOINT,
                    {},
                    { params: { id: provider, code, state } },
                );

                const fromWeb = !!sessionStorage.getItem(WEB_LOGIN_SESSION_KEY);

                if (fromWeb) {
                    sessionStorage.removeItem(WEB_LOGIN_SESSION_KEY);
                    handleTokenResponse(rawTokens);
                } else {
                    directToApp(rawTokens);
                }
                ReactGA.event({
                    category: 'login_success',
                    action: provider,
                });
            } catch (err) {
                ReactGA.event({
                    category: 'login_error',
                    action: provider,
                    label: err?.response?.data?.message || err.message,
                });
                catchError(err);
            }
        },
        [directToApp, handleTokenResponse],
    );

    const logout = async () => {
        if (!tokens.refreshToken()) return;

        try {
            await api.post(
                LOGOUT_ENDPOINT,
                {
                    refreshToken: tokens.refreshToken(),
                },
                { authToken: tokens.authToken() },
            );

            tokens.setTokens({});
            setIsAuthorized(false);
            setUser(undefined);
            ReactGA.event({
                category: 'logout',
                action: 'logout',
            });
        } catch (err) {
            catchError(err);
        }
    };

    const refresh = useCallback(async () => {
        if (!tokens.refreshToken()) return;

        try {
            const rawTokens = await api.post(REFRESH_ENDPOINT, {
                refreshToken: tokens.refreshToken(),
            });

            handleTokenResponse(rawTokens);
        } catch (err) {
            catchError(err);
            tokens.setTokens({});
            setIsAuthorized(false);
            setUser(undefined);
        }
    }, [handleTokenResponse]);

    useEffect(() => {
        refresh().finally(() => {
            setIsInitialising(false);
        });
    }, [refresh]);

    const checkCanRegister = useCallback(
        async (msid: string, providerKey: string, session: string, onSuccess?: () => void) => {
            try {
                await api.post(CHECK_REGISTRATION_ENDPOINT, {
                    msid,
                    providerKey,
                    session,
                });

                if (onSuccess) onSuccess();
            } catch (err) {
                catchError(err);
            }
        },
        [],
    );

    const register = useCallback(
        async (
            email: string,
            password: string,
            newsLetter: boolean,
            extraData?: Record<string, string>,
            onSuccess?: () => void,
        ) => {
            try {
                const rawTokens = await api.post(REGISTER_ENDPOINT, {
                    email,
                    password,
                    newsLetter,
                    ...(extraData ?? {}),
                });

                handleTokenResponse(rawTokens);
                ReactGA.event({
                    category: 'register',
                    action: 'register',
                });
                if (onSuccess) onSuccess();
            } catch (err) {
                catchError(err);
            }
        },
        [handleTokenResponse],
    );

    const connectAccount = useCallback(
        async (msid: string, providerKey: string, session: string, onSuccess?: () => void) => {
            try {
                await api.post(
                    CONNECT_ENDPOINT,
                    {
                        msid,
                        providerKey,
                        session,
                    },
                    { authToken: tokens.authToken() },
                );

                if (onSuccess) onSuccess();
            } catch (err) {
                catchError(err);
            }
        },
        [],
    );

    const cancelContract = useCallback(async (onSuccess?: () => void) => {
        try {
            await api.delete(CANCEL_CONTRACT_ENDPOINT, { authToken: tokens.authToken() });

            if (onSuccess) onSuccess();
        } catch (err) {
            catchError(err);
        }
    }, []);

    const changePassword = async (oldPassword: string, newPassword: string) => {
        try {
            const rawTokens = await api.post(
                CHANGE_PASSWORD_ENDPOINT,
                {
                    oldPassword,
                    newPassword,
                },
                { authToken: tokens.authToken() },
            );

            const tokenTransform = transformAuthTokens(rawTokens);
            tokens.setTokens(tokenTransform);
            ReactGA.event({
                category: 'change_password',
                action: 'change_password',
            });
        } catch (err) {
            catchError(err);
        }
    };

    const forgotPassword = async (email: string) => {
        try {
            await api.post(FORGOT_PASSWORD_ENDPOINT, {
                username: email,
            });
            ReactGA.event({
                category: 'forgot_password',
                action: 'forgot_password',
            });
        } catch (err) {
            catchError(err);
        }
    };

    const resetPassword = useCallback(
        async (code: string, password: string, onSuccess?: () => void) => {
            try {
                await api.post(RESET_PASSWORD_ENDPOINT, {
                    code,
                    password,
                });

                if (onSuccess) onSuccess();
            } catch (err) {
                catchError(err);
            }
        },
        [],
    );

    const deleteDevice = async (deviceId: number) => {
        try {
            await api.delete(DELETE_DEVICE_ENDPOINT, {
                authToken: tokens.authToken(),
                params: { id: deviceId },
            });
            ReactGA.event({
                category: 'remove_device',
                action: 'remove_device',
            });
            fetchUser();
        } catch (err) {
            catchError(err);
        }
    };

    return {
        activateAccount,
        authToken: tokens.authToken(),
        cancelContract,
        changePassword,
        checkCanRegister,
        connectAccount,
        deleteDevice,
        error,
        forgotPassword,
        hasExternalProvider,
        isAuthorized,
        isInitialising,
        login,
        logout,
        oauthLogin,
        refreshUser,
        register,
        resetPassword,
        startOauthLogin,
        user,
    };
};

export function AuthProvider({
    children,
}: React.HTMLAttributes<HTMLDivElement>): JSX.Element | null {
    const auth = useProvideAuth();
    if (auth.isInitialising) return null;

    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default useAuth;
