import $ from './item-title.scss';

interface iProps {
    image: string;
    name: string;
}

export function ItemTitle({ image, name }: iProps) {
    if (image) {
        return <img className={$.logo} src={image} alt={name} height={24} />;
    }

    return <h1 className={$.title}>{name}</h1>;
}
