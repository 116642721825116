import { useCallback, useState } from 'react';

import cmsApi from '~source/core/services/cms-api';

const NEWSLETTER_ENDPOINT = 'api-simple/?module=newsletter&action=subscribe';

export const useNewsletter = () => {
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const subscribe = useCallback(async (email: string) => {
        const data = new FormData();
        data.append('email', email);
        data.append('source', 'ZiggoSportApp');

        try {
            await cmsApi.post(NEWSLETTER_ENDPOINT, data);

            setIsSubscribed(true);
        } catch (err) {
            const message = err.response?.data?.data?.message || 'Aanmelden mislukt.';
            setError(new Error(message));
        }
    }, []);

    return {
        error,
        isSubscribed,
        subscribe,
    };
};
