import { Button } from '../../atoms';
import { Modal } from '../../atoms/modal/Modal';

import $ from './modal.scss';

export function FutureModal({ close }: { close: () => void }) {
    return (
        <Modal>
            <h1>Ziggo Sport</h1>
            <p className={$.modalContent}>
                Deze wedstrijd is nog niet begonnen. De uitzending zal beginnen op de aangegeven
                datum en tijd.
            </p>

            <div className={$.modalButton}>
                <Button label='Oke' onClick={() => close()} />
            </div>
        </Modal>
    );
}
