import cx from 'clsx';

import { Color } from '~models';
import mapPropsToStyles from '~utils/map-props-to-styles';

import $ from './text.scss';

interface iProps extends React.HTMLAttributes<HTMLHeadingElement> {
    color?: Color;
    bold?: boolean;
    className?: string;
}

export function Text({ children, color = 'gray-light', bold, className }: iProps) {
    const classes = cx(mapPropsToStyles({ color, bold }, $), className);

    return <p className={classes}>{children}</p>;
}
