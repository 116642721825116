// import { useCallback, useMemo, useRef, useState } from 'react';
// import { Link, useHistory } from 'react-router-dom';

import { Heading } from '~components';
// import ZiggoIcon from '../../atoms/icon/icons/ziggo';

import $ from './payment-header.scss';

interface iProps {
    active?: number;
}

export function PaymentHeader({ active = 0 }: iProps): JSX.Element {
    return (
        <div className={$.paymentHeader}>
            <div>
                <div>
                    <Heading size='large'>Abonneer</Heading>
                    <div>
                        <span data-done={active - 1 >= 0} data-active={active === 0}>
                            Gegevens
                        </span>
                        <span data-done={active - 1 >= 1} data-active={active === 1}>
                            Bestelling
                        </span>
                        <span data-done={active - 1 >= 2} data-active={active === 2}>
                            Betalen
                        </span>
                        <span data-done={active - 1 >= 3} data-active={active === 3}>
                            Afronden
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
