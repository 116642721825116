import { differenceInHours, fromUnixTime } from 'date-fns';

import {
    iResponseUserDetails,
    iUserDetails,
    iResponseUserProvider,
    iUserProvider,
    iResponseUserCurrentSubscription,
    iUserCurrentSubscription,
    iResponseUserUpcomingSubscription,
    iUserUpcomingSubscription,
    iResponseUserContract,
    iUserContract,
    iResponseUserTransaction,
    iUserTransaction,
    iResponseUserDevice,
    iUserDevice,
    iResponseUser,
    iUser,
} from '~models';

export function transformDetails(value: iResponseUserDetails): iUserDetails {
    return {
        email: value.email,
    };
}

export function transformProvider(value: iResponseUserProvider): iUserProvider {
    return {
        name: value.name,
        external: value.external,
    };
}

export function transformCurrentSubscription(
    value: iResponseUserCurrentSubscription,
): iUserCurrentSubscription {
    return {
        validUntil: fromUnixTime(value.validUntil),
    };
}

export function transformUpcomingSubscription(
    value: iResponseUserUpcomingSubscription,
): iUserUpcomingSubscription {
    return {
        validFrom: fromUnixTime(value.validFrom),
        validUntil: fromUnixTime(value.validUntil),
        status: value.status,
    };
}

export function transformTransaction(value: iResponseUserTransaction): iUserTransaction {
    return {
        subscriptionId: value.subscriptionId,
        title: value.title,
        created: fromUnixTime(value.created),
        validFrom: fromUnixTime(value.validFrom),
        validUntil: fromUnixTime(value.validUntil),
        paid: value.paid,
        videoId: value.videoId,
        videoUrl: value.videoUrl,
    };
}

export function transformContract(value: iResponseUserContract): iUserContract {
    return {
        active: value.active,
    };
}

export function transformDevice(value: iResponseUserDevice): iUserDevice {
    return {
        id: value.id,
        deviceTypeName: value.deviceTypeName,
        lastUpdated: value.lastUpdated ? fromUnixTime(value.lastUpdated) : undefined,
        created: fromUnixTime(value.created),
        canBeDeleted: value.canBeDeleted,
    };
}

export function transformUser(value: iResponseUser): iUser {
    const allTransactions = value.transactions.map(transformTransaction);
    /**
     * Due to some BE workarounds there are accounts with transactions with a very short or even
     * negative duration. To prevent confusion (and crashes), we don't bother showing them to the
     * user. Only transactions that are valid for at least 1 hour are shown.
     */
    const transactions = allTransactions.filter((transaction) => {
        const durationInHours = differenceInHours(transaction.validUntil, transaction.validFrom);
        return durationInHours >= 1;
    });
    return {
        details: transformDetails(value.details),
        provider: transformProvider(value.provider),
        currentSubscription:
            value.currentSubscription && transformCurrentSubscription(value.currentSubscription),
        upcomingSubscription:
            value.upcomingSubscription && transformUpcomingSubscription(value.upcomingSubscription),
        contract: transformContract(value.contract),
        transactions,
        devices: value.devices.map(transformDevice),
    };
}
