import { useLayoutEffect } from 'react';

export function useBodyScroll(callback: (scrollY: number) => void, dependencies: any[] = []) {
    useLayoutEffect(() => {
        let requestRunning: number | undefined;
        function handleScroll() {
            if (requestRunning === undefined) {
                requestRunning = window.requestAnimationFrame(() => {
                    callback(document.body.scrollTop);
                    requestRunning = undefined;
                });
            }
        }

        document.body.addEventListener('scroll', handleScroll);
        return function cancelListener() {
            document.body.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
}
