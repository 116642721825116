import { isWithinInterval as isWithinIntervalFns } from 'date-fns';

export function isWithinInterval(
    startTime: number | Date,
    endTime: number | Date,
    now: number | Date,
    defaultValue: boolean = false,
): boolean {
    try {
        return isWithinIntervalFns(now, {
            start: startTime,
            end: endTime,
        });
    } catch (err) {
        // eslint-disable-next-line no-console
        // console.error(`Error: StartTime: ${startTime} is after EndTime: ${startTime}:`, err);
        return defaultValue;
    }
}
