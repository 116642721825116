import Axios from 'axios';
import { Config, params } from './params';

export type ResponseMapPost = {
    'api-simple/?module=newsletter&action=subscribe': unknown;
};

const cmsApi = Axios.create({
    // Use optional chaining for the test suite
    baseURL: window?.APP_SETTINGS?.cmsUrl,
});

export default {
    /**
     * POST request
     */
    async post<P extends keyof ResponseMapPost>(path: P, data: any, config?: Config) {
        const [apiPath, apiConfig] = params(path, config);
        const response = await cmsApi.post<ResponseMapPost[P]>(apiPath, data, apiConfig);
        return response.data;
    },
};
