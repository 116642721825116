import { iIconProps } from '../../icon';

export default function InstagramIcon({ size, ...props }: iIconProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox='0 0 40 40' {...props}>
            <path d='M27.169 4H12.831C7.96 4 4 7.962 4 12.83v14.34C4 32.038 7.961 36 12.83 36H27.17C32.039 36 36 32.038 36 27.17V12.83C36 7.963 32.039 4 27.169 4zm5.992 23.17a5.999 5.999 0 01-5.992 5.99H12.831a5.998 5.998 0 01-5.992-5.99V12.83a5.998 5.998 0 015.992-5.99h14.338a5.999 5.999 0 015.992 5.99v14.34z' />
            <path d='M20 11.755c-4.547 0-8.246 3.699-8.246 8.245 0 4.547 3.699 8.246 8.246 8.246 4.546 0 8.245-3.699 8.245-8.245 0-4.547-3.699-8.246-8.245-8.246zm0 13.651A5.412 5.412 0 0114.593 20 5.412 5.412 0 0120 14.594 5.412 5.412 0 0125.405 20 5.412 5.412 0 0120 25.406zM28.592 9.347a2.09 2.09 0 00-1.47.61 2.092 2.092 0 00-.612 1.473 2.1 2.1 0 00.611 1.472c.386.386.924.61 1.47.61.55 0 1.085-.224 1.473-.61.389-.388.61-.925.61-1.472a2.09 2.09 0 00-.61-1.473 2.09 2.09 0 00-1.472-.61z' />
        </svg>
    );
}
