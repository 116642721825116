import Axios from 'axios';

import { Config, params } from './params';
import {
    iResponseAuthTokens,
    iResponseProviderForm,
    iResponseProviders,
    iResponseEpg,
    iResponseFaq,
    iResponseStreams,
    iResponseUser,
    iResponseVideo,
    iResponseInit,
    iResponseMissedItem,
    iResponsePurchaseParams,
    iResponsePurchaseCode,
    iResponsePurchaseExecuted,
    iResponseUefa,
    iResponsePlayer,
} from '~source/core/models';
import { readRefreshToken } from '~source/utils/tokens';
import { transformAuthTokens } from '../transformers/auth';
import { tokens } from '~hooks/use-auth';
import { iResponseSportbuffToken } from '~source/core/models/response-sportbuff-token';

export type ResponseMapGet = {
    'providers/{id}/form': iResponseProviderForm;
    providers: iResponseProviders;
    epg: iResponseEpg;
    faq: iResponseFaq;
    streams: iResponseStreams;
    'missed-item/{id}': iResponseMissedItem;
    video: iResponseVideo;
    'streams/{id}/uefa-url': iResponseUefa;
    user: iResponseUser;
    contentIndex: iResponseInit;
    'purchase/status/{transactionId}': iResponsePurchaseExecuted;
};

export type ResponseMapPost = {
    activation: unknown;
    'authorization/provider/oauth/{id}': iResponseAuthTokens;
    'authorization/provider/{id}': iResponseAuthTokens;
    'authorization/logout': iResponseAuthTokens;
    'authorization/refresh': iResponseAuthTokens;
    register: iResponseAuthTokens;
    'change-password': iResponseAuthTokens;
    'user/check/kpn': unknown;
    'forgot-password': iResponseAuthTokens;
    'reset-password': any;
    player: iResponsePlayer;
    'user/connect/kpn': any;
    'purchase/params': iResponsePurchaseParams;
    'purchase/code': iResponsePurchaseCode;
    'purchase/executed': iResponsePurchaseExecuted;
    'sport-buff/token': iResponseSportbuffToken;
};

export type ResponseMapDelete = {
    'user/contract': unknown;
    'device/{id}': unknown;
};

const REFRESH_ENDPOINT = 'authorization/refresh';

const api = Axios.create({
    // Use optional chaining for the test suite
    baseURL: window?.APP_SETTINGS?.apiUrl,
    auth: window?.APP_SETTINGS?.apiBasicAuth,
});

api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        const { authToken } = originalRequest;

        if (error.response.status === 401 && authToken && !originalRequest.retry) {
            // When the device limit is reached we also receive a 401 response. The response data
            // is the main differentiator between these two responses.
            if (originalRequest.url === 'player' && error.response.data?.text) {
                return Promise.reject(error);
            }

            originalRequest.retry = true;
            const rawTokens = await api.post(REFRESH_ENDPOINT, {
                refreshToken: readRefreshToken(),
            });

            const tokenTransform = transformAuthTokens(rawTokens.data);
            tokens.setTokens(tokenTransform);

            if (originalRequest.url === 'authorization/logout') {
                const refreshToken = tokens.refreshToken();
                originalRequest.data = JSON.stringify({ refreshToken });
            }

            originalRequest.authToken = tokenTransform.authToken;
            originalRequest.headers['X-AUTH-TOKEN'] = tokenTransform.authToken;
            return api(originalRequest);
        }
        return Promise.reject(error);
    },
);

export default {
    /**
     * GET request
     */
    async get<P extends keyof ResponseMapGet>(path: P, config?: Config) {
        const [apiPath, apiConfig] = params(path, config);
        const response = await api.get<ResponseMapGet[P]>(apiPath, apiConfig);
        return response.data;
    },

    /**
     * POST request
     */
    async post<P extends keyof ResponseMapPost>(path: P, data: any, config?: Config) {
        const [apiPath, apiConfig] = params(path, config);
        const response = await api.post<ResponseMapPost[P]>(apiPath, data, apiConfig);
        return response.data;
    },

    /**
     * DELETE request
     */
    async delete<P extends keyof ResponseMapDelete>(path: P, config?: Config) {
        const [apiPath, apiConfig] = params(path, config);
        const response = await api.delete<ResponseMapDelete[P]>(apiPath, apiConfig);
        return response.data;
    },
};

export const testable = { params };
