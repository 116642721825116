import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import api from '~source/core/services/api';
import { iInit } from '~models';
import { transformInit } from '~source/core/transformers/init';
import { useAsync } from '~hooks/use-async';
import useAuth from '~hooks/use-auth';
import { useDeviceInfo } from './use-device-info';

const INIT_ENDPOINT = 'contentIndex';

interface iInitContext {
    data: iInit | null;
    error: Error | null;
    loading: boolean;
    refreshInit: () => Promise<void>;
}

const initContext = createContext<iInitContext>({
    data: null,
    error: null,
    loading: false,
    refreshInit: async () => undefined,
});

export const useInit = () => useContext(initContext);

export function useProvideInit() {
    // Keep a reference to old data so that during a refresh we don't unload the whole app.
    const [data, setData] = useState<iInit | null>(null);
    const { authToken, refreshUser } = useAuth();
    const deviceInfo = useDeviceInfo();

    const [initData, error, loading, { startReload }] = useAsync(
        async (signal: AbortSignal) => {
            const rawInit = await api.get(INIT_ENDPOINT, {
                signal,
                authToken,
                headers: {
                    'X-App-Token': deviceInfo.deviceId,
                    'X-App-Version': deviceInfo.appVersion,
                    'X-Device': deviceInfo.browserName,
                    'X-Os-Version': deviceInfo.browserVersion,
                },
            });

            return transformInit(rawInit);
        },
        null,
        [authToken],
    );

    useEffect(() => {
        if (!initData) return;
        setData(initData);
    }, [initData]);

    const refreshInit = useCallback(async () => {
        if (startReload) startReload();
        await refreshUser();
    }, [startReload, refreshUser]);

    return { data, error, loading, refreshInit };
}

export function InitProvider({
    children,
}: React.HTMLAttributes<HTMLDivElement>): JSX.Element | null {
    const init = useProvideInit();
    if (init.loading && !init.data) return null;

    return <initContext.Provider value={init}>{children}</initContext.Provider>;
}
