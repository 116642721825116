import { useCallback, useState } from 'react';
import { Text, Button } from '~components';
import { iStream } from '~models';
import { FALLBACK_COMPETITION_LOGO_PATH } from '~constants';

import format from '~utils/format-date';

import $ from './stream.scss';

interface iProps {
    stream: iStream;
    hasSubscription?: boolean;
    onWatch: () => void;
    onOrder: () => void;
}

export function Stream({ stream, hasSubscription, onWatch, onOrder }: iProps): JSX.Element {
    const renderPrice = () => {
        if (stream.free) {
            return <Text>Gratis</Text>;
        }
        if (stream.goOnly) {
            return <Text>Ziggo Sport Totaal Go Only</Text>;
        }
        if (hasSubscription) {
            return null;
        }
        return <Text>€ 3,99</Text>;
    };

    const [innerLogoUrl, setInnerLogoUrl] = useState(stream.competitionLogo);
    const setFallback = useCallback(() => {
        setInnerLogoUrl(FALLBACK_COMPETITION_LOGO_PATH);
    }, []);

    return (
        <div className={$.container}>
            <div className={$.descriptionContainer}>
                <div className={$.date}>
                    <Text>{format(stream.kickoffTime, 'HH:mm')}</Text>
                </div>
                <div className={$.logo}>
                    <img
                        src={innerLogoUrl}
                        alt={stream.competition}
                        height={36}
                        onError={setFallback}
                    />
                </div>
                <div className={$.title}>
                    <Text color='white'>{stream.title}</Text>
                </div>
            </div>
            <div className={$.priceContainer}>
                <div className={$.price}>{renderPrice()}</div>
                <div className={$.button}>
                    {stream.free || stream.paid ? (
                        <Button label='Bekijk' onClick={onWatch} />
                    ) : (
                        <Button label='Bestel' onClick={onOrder} />
                    )}
                </div>
            </div>
        </div>
    );
}
