import { format as dateFormat } from 'date-fns';
import { nl } from 'date-fns/locale';

const format = (date: Date, formatStr: string = 'PP') => {
    return dateFormat(date, formatStr, {
        locale: nl,
    });
};

export default format;
