import { iResponseProviderForm, iProviderForm } from '~source/core/models';

export function transformProviderForm(value: iResponseProviderForm): iProviderForm {
    return {
        usernameTitle: value.usernameTitle,
        usernamePlaceholder: value.usernamePlaceholder,
        passwordHidden: value.passwordHidden,
        passwordTitle: value.passwordTitle,
        passwordPlaceholder: value.passwordPlaceholder,
        logo: value.logo,
        authenticateUrl: value.authenticateUrl,
    };
}
