import cx from 'clsx';

import { Text } from '~components';
import { iEvent } from '~models';

import $ from './event-select.scss';

interface iProps {
    event: iEvent;
    updateEvent: (event: iEvent) => void;
    active?: boolean;
    showImage?: boolean;
}

export function EventSelect({ event, active, updateEvent, showImage }: iProps): JSX.Element {
    return (
        <div
            className={cx($.container, active && $.active)}
            onClick={() => updateEvent(event)}
            onKeyPress={() => updateEvent(event)}
            role='button'
            tabIndex={0}
        >
            {showImage && event.channelImageLight && (
                <img
                    className={$.logo}
                    src={event.channelImageLight}
                    alt={event.channelName}
                    height={24}
                />
            )}
            <Text className={$.name}>{event.channelName}</Text>
        </div>
    );
}
