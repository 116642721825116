import { useLayoutEffect } from 'react';

export function useResize(callback: () => void, dependencies: any[] = []) {
    useLayoutEffect(() => {
        let requestRunning: number | undefined;
        function handleScroll() {
            if (requestRunning === undefined) {
                requestRunning = window.requestAnimationFrame(() => {
                    callback();
                    requestRunning = undefined;
                });
            }
        }

        window.addEventListener('resize', handleScroll);
        return () => {
            window.removeEventListener('resize', handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
}
