import { useMemo } from 'react';
import { iBrowserDevice } from '~source/core/models';
import { generateGuid } from '~utils/guid';
import { getBrowserInfo } from '~utils/jw-browser-info';
import { version } from '../../../package.json';

const DEVICE_ID_KEY = 'ZST_DEVICE_ID';

interface iDeviceInfo {
    appVersion: string;
    browserName: iBrowserDevice;
    browserVersion: string;
    deviceId: string;
}

export const useDeviceInfo = (): iDeviceInfo => {
    const deviceId = useMemo(() => {
        const existingDeviceId = localStorage.getItem(DEVICE_ID_KEY);
        if (existingDeviceId) {
            return existingDeviceId;
        }

        const newDeviceId = generateGuid();
        localStorage.setItem(DEVICE_ID_KEY, newDeviceId);
        return newDeviceId;
    }, []);

    const { browserName, browserVersion } = useMemo(getBrowserInfo, []);

    const deviceInfo = useMemo(() => {
        return {
            appVersion: version,
            browserName,
            browserVersion,
            deviceId,
        };
    }, [browserName, browserVersion, deviceId]);

    return deviceInfo;
};
