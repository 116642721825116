import { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { Heading, Button, Input, Checkbox, Message } from '~components';
import useAuth from '~hooks/use-auth';

import $ from './register-form.scss';

interface iProps {
    className?: string;
    extraData?: Record<string, string>;
    onSuccess?: () => void;
}

const RegisterSchema = Yup.object().shape({
    email: Yup.string().email('Ongeldig e-mailadres').required('Verplicht'),
    password: Yup.string().required('Verplicht'),
    confirmPassword: Yup.string().when('password', {
        is: (val: string) => val && val.length > 0,
        then: Yup.string()
            .oneOf([Yup.ref('password')], 'Wachtwoorden komen niet overeen')
            .required(),
    }),
    terms: Yup.boolean().oneOf(
        [true],
        'Je dient akkoord te gaan met de Algemene voorwaarden en de Ziggo Sport Totaal Privacy Policy',
    ),
    newsLetter: Yup.boolean(),
});

const RegisterForm: React.FC<iProps> = ({ className, extraData = {}, onSuccess }) => {
    const { register } = useAuth();

    const [registerDone, setRegisterDone] = useState<boolean>(false);
    const [registerError, setRegisterError] = useState<string | undefined>();

    const handleRegister = ({
        email,
        password,
        newsLetter,
    }: {
        email: string;
        password: string;
        newsLetter: boolean;
    }) => {
        register(email, password, newsLetter, extraData, onSuccess)
            .then(() => {
                setRegisterDone(true);
            })
            .catch((err: Error) => {
                setRegisterError(err.message);
            });
    };

    return (
        <div className={className}>
            <Heading>Nieuw bij Ziggo Sport Totaal Go</Heading>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    confirmPassword: '',
                    terms: false,
                    newsLetter: false,
                }}
                validationSchema={RegisterSchema}
                onSubmit={handleRegister}
            >
                <Form>
                    {registerDone && <Message message='U bent succesvol geregistreerd' />}
                    {registerError && <Message.Error message={registerError} />}
                    <Input
                        name='email'
                        type='email'
                        label='E-mailadres'
                        placeholder='E-mailadres'
                        autoComplete='username'
                    />
                    <Input
                        name='password'
                        type='password'
                        label='Wachtwoord'
                        placeholder='Wachtwoord'
                        autoComplete='new-password'
                    />
                    <Input
                        name='confirmPassword'
                        type='password'
                        label='Bevestig wachtwoord'
                        placeholder='Bevestig wachtwoord'
                        autoComplete='new-password'
                    />
                    <div className={$.terms}>
                        <Checkbox name='terms' showError={false} />
                        <label htmlFor='terms'>
                            Ja, ik ga akkoord met de{' '}
                            <a
                                target='_blank'
                                rel='noreferrer'
                                href='https://ziggosport.nl/gebruikersvoorwaarden/'
                            >
                                Algemene voorwaarden
                            </a>{' '}
                            en de{' '}
                            <a
                                target='_blank'
                                rel='noreferrer'
                                href='https://ziggosport.nl/privacy-statement/'
                            >
                                Privacy Policy
                            </a>{' '}
                            voor Ziggo Sport Totaal Go.
                        </label>
                    </div>
                    <Checkbox
                        name='newsLetter'
                        label='Ja, ik wil graag sport highlights en het belangrijkste sportnieuws van Ziggo Sport ontvangen.'
                    />
                    <Button type='submit' label='Registreren' disabled={registerDone} />
                </Form>
            </Formik>
        </div>
    );
};

export default RegisterForm;
