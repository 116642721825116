import cx from 'clsx';

import { Color } from '~models';
import mapPropsToStyles from '~utils/map-props-to-styles';

import $ from './icon.scss';
import * as icons from './icons';

interface iProps extends React.SVGAttributes<SVGElement> {
    size?: number;
    color?: Color;
    className?: string;
    type: keyof typeof icons;
}

export type iIconProps = Omit<iProps, 'type'> & { size: Required<iProps>['size'] } & {
    className: string;
};

export function Icon({
    type,
    color = 'white',
    size = 32,
    className,
    ...props
}: iProps): JSX.Element | null {
    const IconElement = icons[type];
    const classes = cx(mapPropsToStyles({ color }, $), className);

    return (
        <IconElement
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            size={size}
            className={classes}
            {...props}
        />
    );
}
