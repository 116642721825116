import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { APP_VERSION } from '~source/core/constants';
import App from '~source/ui/App';

document.body.setAttribute('data-app-version', APP_VERSION);
const mountElement = document.getElementById('app') as HTMLElement;

const root = createRoot(mountElement);

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
);
