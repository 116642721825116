import { Item, Heading, Text } from '~components';
import { iMediaItem } from '~models';

import format from '~utils/format-date';

import $ from './media-item.scss';

interface iProps {
    item: iMediaItem;
}

export function MediaItem({ item }: iProps): JSX.Element {
    const duration = () => {
        if (item?.duration) {
            return new Date(item.duration * 1000).toISOString().substr(11, 8);
        }

        return undefined;
    };

    return (
        <Item
            link={`/missed/${item.id}`}
            title={
                <>
                    <Heading size='small' className={$.title}>
                        {item.title}
                    </Heading>
                    <Text color='white'>{format(item.imported, 'EEEE d MMMM')}</Text>
                </>
            }
            imageUrl={item.imageUrl}
            duration={duration()}
        />
    );
}
