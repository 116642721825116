export { default as facebook } from './brands/facebook';
export { default as instagram } from './brands/instagram';
export { default as twitter } from './brands/twitter';
export { default as youtube } from './brands/youtube';
export { default as x } from './brands/x';
export { default as tiktok } from './brands/tiktok';
export { default as podcast } from './brands/podcast';

export { default as arrowRight } from './arrowRight';
export { default as check } from './check';
export { default as chevronDown } from './chevronDown';
export { default as close } from './close';
export { default as mail } from './mail';
export { default as menu } from './menu';
export { default as play } from './play';
export { default as tv } from './tv';
export { default as user } from './user';
export { default as ziggo } from './ziggo';
export { default as ziggoSportTotaal } from './ziggoSportTotaal';
