import { format, isAfter, setDefaultOptions } from 'date-fns';
import { nl } from 'date-fns/locale';

import $ from './ott-title.scss';

interface Props {
    title: string | undefined;
    date: Date | undefined;
}

export function OTTTitle({ title, date }: Props) {
    setDefaultOptions({ locale: nl });

    if (date && isAfter(date, new Date())) {
        return (
            <div className={$.wrapper}>
                <p className={$.title}>{title}</p>
                <span className={$.subTitle}>
                    Uitzending begint op {format(date, 'dd MMMM')} om {format(date, 'HH:mm')}
                </span>
            </div>
        );
    }

    return (
        <p className={$.wrapper}>
            <p className={$.title}>{title}</p>
        </p>
    );
}
