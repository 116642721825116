import { Heading } from '~components';

import $ from './large-heading.scss';

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement>;
interface iProps {
    title: string;
    subtitle: string;
    image: ImageProps & Required<Pick<ImageProps, 'alt'>>;
}

export function LargeHeading({ image, title, subtitle }: iProps): JSX.Element {
    return (
        <div className={$.container}>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img {...image} />
            <div className={$.gradient} />
            <div className={$.content}>
                <Heading size='small' className={$.title}>
                    {title}
                </Heading>
                <Heading size='smaller' className={$.subtitle}>
                    {subtitle}
                </Heading>
            </div>
        </div>
    );
}
