import { iResponseProviders, iResponseProvider, iProviders, iProvider } from '~source/core/models';

function transformProvider(value: iResponseProvider): iProvider {
    return {
        id: value.id,
        name: value.name,
        image: value.image,
        default: value.default,
        externalUrl: value.externalUrl,
    };
}

export function transformProviders(value: iResponseProviders): iProviders {
    return {
        providers: value.providers.map(transformProvider),
    };
}
