import cx from 'clsx';

import mapPropsToStyles from '~utils/map-props-to-styles';

import $ from './heading.scss';

interface iProps extends React.HTMLAttributes<HTMLHeadingElement> {
    size?: 'smaller' | 'small' | 'medium' | 'large';
    className?: string;
}

export function Heading({ children, size = 'medium', className, ...props }: iProps) {
    const styles = cx(mapPropsToStyles({ size }, $), className);

    switch (size) {
        case 'large':
            return (
                <h1 {...props} className={styles}>
                    {children}
                </h1>
            );
        // eslint-disable-next-line default-case-last
        default:
        case 'medium':
            return (
                <h2 {...props} className={styles}>
                    {children}
                </h2>
            );
        case 'small':
            return (
                <h3 {...props} className={styles}>
                    {children}
                </h3>
            );
        case 'smaller':
            return (
                <h4 {...props} className={styles}>
                    {children}
                </h4>
            );
    }
}
