import { Route as ReactRoute, Redirect } from 'react-router-dom';

import { iRoute } from '~source/ui/routes';
import useAuth from '~hooks/use-auth';

export function Route(route: iRoute) {
    const { isAuthorized } = useAuth();

    if (route.protected && !isAuthorized) {
        return <Redirect to={{ pathname: '/login' }} />;
    }

    return (
        <ReactRoute
            key={route.path}
            exact={route.exact !== false}
            path={route.path}
            component={route.component}
        />
    );
}
